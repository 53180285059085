<template>
	<div class="contact">
		<div class="top">
			<div class="topCon">当你遇到任何问题，可以联系我们哦</div>
		</div>
		<div class="wx">
			<div class="wxCon">
				<div class="wx1">
					<p>微信联系我们</p>
					<p>截图保存至本地，打开微信扫一扫 添加客服微信好友</p>
					<p>微信号：18888888888</p>
					<div class="img">
						<img src="../../assets/mine/wx.jpg" alt="" style="width: 2.5rem;height: 2.5rem;">
					</div>
					<p>推荐通过微信联系我们，更加便捷，方便提供出现问题的页面，或者相关问题的截图</p>
				</div>
				<div class="wx1">
					<p>电话联系我们</p>
					<p>当您需要预约用餐,请直接按照商品详情介绍流程进行预约即可，如短信预约、短链接预约、电话预约等。如遇到需要支付或退款等问题，请联系微信客服处理(电话客服无法处理)。微信客服如未回复，留言发送订单号和事由，微信客服收到信息后会及时处理。</p>
					<p>如有其他异常，请联系我们</p>
					<p>客服热线:</p>
					<p>0571-85199986</p>
				</div>
			</div>
		</div>
		<!-- <div class="wx">
			<div class="wxCon">
				<div class="wx1">
					<p>电话联系我们</p>
					<p>当您需要预约用餐,请直接按照商品详情介绍流程进行预约即可，如短信预约、短链接预约、电话预约等。如遇到需要支付或退款等问题，请联系微信客服处理(电话客服无法处理)。微信客服如未回复，留言发送订单号和事由，微信客服收到信息后会及时处理。</p>
					<p>如有其他异常，请联系我们</p>
					<p>客服热线:</p>
					<p>0571-85199986</p>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		}
	}
</script>

<style  lang="less" scoped>
	@import "~@/assets/style/preset.less";
	.contact{
		width: 100%;
		// height: 100%;
		.top{
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			// margin-top: 30px;
			height: 60px;
			.topCon{
				width: 90%;
				height: 40px;
				line-height: 40px;
				box-shadow: 2px 2px 5px 2px #ccc;
				font-size: 0.25rem;
				text-align: center;
			}
		}
		.wx{
			width: 100%;
			display: flex;
			justify-content: center;
			.wxCon{
				width: 90%;
				background-color: #FFFFFF;
				box-shadow: 2px 2px 5px 2px #ccc;
				font-size: 0.22rem;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				.wx1{
					width: 92%;
					p{
						font-size: 0.2rem;
					}
					.img{
						width: 100%;
						display: flex;
						justify-content: center;
					}
				}
				
			}
		}
	}
</style>
